// createLead
import axios from "axios";

import { RepositoryFactory } from "../../repository/RepositoryFactory";
let LeadRepo = RepositoryFactory.get("newLead");

export const createNewLead =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    let { bootCheck, ...payload } = data;
    console.log("Payload before sending", payload);

    if (payload.answers.includes(4511) || bootCheck) {
      dispatch({
        type: "SET_LEAD_DETAILS",
        payload: {},
      });
      onSuccess();
    } else {
      await dispatch(setLeadLoading(true));
      try {
        let { data } = await LeadRepo.createNewLead(payload);
        if (data) {
          dispatch({
            type: "SET_LEAD_DETAILS",
            payload: data,
          });
          onSuccess();
          await dispatch(setLeadLoading(false));
        } else {
          await dispatch(setLeadLoading(false));
        }
      } catch (e) {
        if (e.response.data.error == "Conflict - duplicate") {
          dispatch({
            type: "SET_LEAD_DETAILS",
            payload: "data",
          });
          onSuccess();
        } else {
          // alert(e.response.data.error);
          let error = e.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
          dispatch({
            type: "ERROR_RESPONSE",
            payload: { [error]: true },
          });
          if (error == "date_of_birth") {
            dispatch({
              type: "SET_LEAD_DETAILS",
              payload: "data",
            });
            onSuccess();
          }
        }
        await dispatch(setLeadLoading(false));
      }
    }
  };
export const setErrorResponse = () => async (dispatch) => {
  dispatch({
    type: "ERROR_RESPONSE",
    payload: null,
  });
};
export const setLeadLoading = (val) => async (dispatch) => {
  dispatch({
    type: "SET_LEAD_LOADING",
    payload: val,
  });
};
