import React from "react";
import stopOverge from "../assets/img/stop-overge-logo.png";
import { Container } from "reactstrap";

const Header = () => {
  return (
    <Container>
      <div className="d-flex justify-content-end w-100 main-header py-4">
        <img src={stopOverge} className="img-fluid " />
      </div>
    </Container>
  );
};

export default Header;
