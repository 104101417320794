import Bedankt from "../views/Bedankt";
import Home from "../views/Home";

let routes = [
  // {
  //   path: "/",
  //   component: Home,
  //   layout: "main",
  // },
  // {
  //   path: "/bedankt",
  //   component: Bedankt,
  //   layout: "main",
  // },
];
export default routes;
