import Header from "../components/Header";

const Main = (props) => {
  return (
    <>
      <div
        className="d-flex flex-column main-content "
        // style={{ minHeight: "100vh" }}
      >
        <div className="main-content__body">
          <Header />
          <div>{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default Main;
